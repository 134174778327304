import React from "react"
import { navigate } from "gatsby"

const salonSearchElement = props => (
  <li className="cursor-pointer search-result-hover ph3 pv1">
    {/* <img src={props.salon_chain_brand.logo} alt=""/> */}
    <div className="uk-text-lead" onClick={() => navigate(`/salon/${props.id}`)}>{props.name}</div>
    <div className="uk-text-meta">{props.location}</div>
  </li>
)

export default salonSearchElement
