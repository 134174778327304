import React, { useContext } from "react"
import { navigate } from "gatsby"
import { searchContext } from "../../../context/searchContext/searchContext"
import { base } from "../../../config/urls"

const SearchButton = ({ redirect }) => {
  const ctx = useContext(searchContext);
  const navigateToListing = () => {
    navigate("/listing")
  }

  return (<button
    onClick={() => {
      ctx.setLocation(null)
      ctx.searchTextBaseSalon()
      if(redirect)
        navigateToListing()
    }}
    className="uk-button uk-button-large"
    style={{
      background:
        "transparent linear-gradient(75deg, #D41959 0%, #FF6F00 100%) 0% 0% no-repeat padding-box",
      color: "white"
    }}
  >
    Search
  </button>)
}

export default SearchButton
