import React, { useContext, useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import { cartContext } from "../../../context/cartContext/cartContext"
import pic from "../../../images/hair-stylist.png";


const SalonListElement = (
  // { salon }
) => {
  const cartCtx = useContext(cartContext)
  let services = [1,2,3,4,5];
  // if (salon.services) services = salon.services

  let [addServiceToCart, setAddServiceToCart] = useState(null);

  useEffect(() => {
    if (addServiceToCart != null && cartCtx.addServiceToCart(addServiceToCart)) {
      navigate(`/salon`);
      setAddServiceToCart(null);
    }
  }, [addServiceToCart]);

  return (

    <div className="salon-card uk-card uk-card-small uk-card-muted pa3 br3 ba b--black-05 relative mb5"
         style={{ background: "white" }}>
      <div className="salon-card-head flex flex-column flex-row-ns">
        <div className="w-100 w-60-ns flex flex-column flex-row-ns">
          <div className="w-100 w-40-ns">
            <img
              src={pic}
              className="w-100" alt=""
              onClick={() => navigate(`/salon`)}
            />
          </div>
          <article className="pv2 ph3 w-100 w-60-ns">
            <div className="uk-text-lead"
                 onClick={() => navigate(`/salon`)}>
              {"Brand Name"} - {"Salon Name"}
            </div>
            <div className="uk-text-meta">distance in number</div>
            <div className="mv3">
              <div className="uk-text-emphasis mb1">
                {"Loaction"}
              </div>
              <a  target="_blank" className="uk-text-primary">
                <span className="uk-icon-link uk-text-primary" uk-icon="location"></span> View on Map
              </a>
            </div>
            <div className="flex flex-row justify-between pv2">
              <div>Salon Rating {5.0}</div>
              <Link to={`/salon`}>View Salon</Link>
            </div>
          </article>
        </div>
        <div className="w-100 w-40-ns pa3 bl b--black-10">
          <div className="uk-text-primary" style={{ fontSize: "20px" }}>
            Popular Services
          </div>
          <table className="uk-table uk-table-middle w-100" style={{ marginTop: "0px", marginBottom: "0px" }}>
            <tbody>
            {services.slice(0,3).map((service) => (
              <tr>
                <td className="uk-table-expand">
                  <p className="mb0">{"Service Name"}</p>
                  <p className="uk-text-meta mt0">{"Service Duration"} minutes</p>
                </td>
                <td>
                  <p className="uk-text-muted">
                    ₹ <span>{"Cost"}</span>
                  </p>
                </td>
                <td className="uk-text-right">
                  <button onClick={() => {
                    if (cartCtx.addServiceToCart(service)) {
                      navigate(`/salon`);
                    }
                    else {
                      window.UIkit.modal.confirm('To add this product you will have to clear your cart. Do you wish to continue?')
                        .then(response => {
                          cartCtx.emptyCart();
                          setAddServiceToCart(service);
                        })
                    }
                  }}
                          className="uk-button uk-button-primary uk-button-small uk-border-pill">
                    Book Now
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <div id={"salon" + 1} className="salon-card-body" hidden>
        <div className="flex flex-column flex-row-ns pv3">
          <div className="w-100 w-60-ns pr3-ns">
            <div className="uk-text-primary" style={{ fontSize: "20px" }}>
              Salon Details
            </div>
            <p>
              {"Brand Info"}
            </p>
          </div>
          <div className="w-100 w-40-ns">
            <div className="uk-text-primary" style={{ fontSize: "20px" }}>
              Salon Timing
            </div>
            <table className="uk-table w-100 w-60-ns" style={{ marginTop: "0px", marginBottom: "0px" }}>
              <tbody>
              <tr>
                <td>
                  {"Opening Timing"}
                </td>
                <td>
                  -
                </td>
                <td>
                  {"Closing Time"}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0" style={{ left: "50%", marginBottom: "-20px" }}>
        <div className="flex flex-column items-center cursor-pointer"
             data-uk-toggle={`target: #${"salon" + 1}; animation: uk-animation-fade`}>
          <div className="uk-text-small uk-text-muted">
            Know More
          </div>
          <div className="ba b--black-10 uk-border-circle" style={{ padding: "3px 5px 3px 5px" }}>
            <span className="uk-icon-link" data-uk-icon="chevron-down"></span>
          </div>
        </div>
      </div>
    </div>


  )
}

export default SalonListElement

//TODO: add services here
