import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import { window } from "browser-monads"
import { searchContext } from "../../../context/searchContext/searchContext"
import { base } from "../../../config/urls"

const NearMeButton = ({ redirect }) => {
  const ctx = useContext(searchContext)
  const fetchUserLocation = () => {
    // if (navigator.geolocation) {
    window.navigator.geolocation.getCurrentPosition(({ coords }) => {
      ctx.searchString = ''
      ctx.setLocation({
        lat: coords.latitude,
        long: coords.longitude
      })
        // ctx.searchLocationBaseSalon()
      if (redirect) {
        navigate("/listing")
      }
    })
    // } else {
    //   alert('Location feature not supported by browser');
    // }
  }
  return (
    <button onClick={fetchUserLocation}
            className="uk-button uk-button-large uk-button-default color-green"
            style={{border: "none"}}>
      <span className="uk-margin-small-right" uk-icon="icon: location"></span>
      Near Me
      {/* <span uk-icon="icon:  chevron-down"></span> */}
    </button>
  )
}
export default NearMeButton
