import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators"
import { forkJoin, of, from } from "rxjs"
import { URLS } from "../../config/urls"
import axios from "axios"
import { SalonsService } from "../../services/salon-service"

export class SearchService {
  constructor() {}

  // terms is an observable of time string
  search(terms) {  
    return terms.pipe(
      // filter(text => text.length > 1),
      debounceTime(50),
      distinctUntilChanged(),
      switchMap(term => this.searchEntries(term))
    )
  }

  searchEntries(term) {
    if (term.length === 0) {
      return of([])
    } else {
      return forkJoin([
        from(axios.get(URLS.serviceSearchUrl(term))),
        from(axios.get(URLS.salonSearchUrl(term))),
      ])
    }
  }

  clearSearch(clear) {
    return this.clearSearch$.next(clear)
  }

  setSearchResults(results, callback) { 
    if (results && results.length) {
      const salonResults = results[1].data.name_suggest__completion[0].options
      this.createSearchResultsWithServices(
        results[0].data.name_suggest__completion[0].options,
        salonResults,
        callback
      )
    } else {
      this.results = []
      callback(this.results)
    }
  }

  createSearchResultsWithServices(services, salonResults, callback) {
    let serviceSalons = []
    const salonRequests = []

    const salonService = new SalonsService();

    //results from salons
    salonResults.forEach(element => {
      salonRequests.push(from(salonService.getSalonDetails(element._source.id)))
    })

    // results from services
    services.forEach(element => {
      const salons = JSON.parse(element._source.salon)
      salons.forEach(salon => {
        salonRequests.push(from(salonService.getSalonDetails(salon.pk)))
      })
    })

    forkJoin(salonRequests).subscribe(response => {
      serviceSalons = [...serviceSalons, ...response].map(salon => salon.data);
      const results = this.setResults(serviceSalons)
      callback(results);
    })
  }

  setResults(results) {
    const map = new Map()
    const searchResults = []
    for (const item of results) {
      if (!map.has(item.id)) {
        map.set(item.id, true)
        searchResults.push(item)
      }
    }

    return searchResults;
  }
}
