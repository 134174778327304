import React from "react"
import SalonSearchElement from "./SalonSearchElement/SalonSearchElement"


const salonSearchList = ({list}) =>{
  return(
    <ul className="uk-container uk-container-medium uk-list">
      {
        list.map(salon=><SalonSearchElement key={salon.id} {...salon}/>)
      }
    </ul>
  )
}

export default salonSearchList;