import React, { useState } from "react"
import SalonListElement from "./salon-list-element/salon-list-element"

const Paginator = ({ pageNumber, totalPages, setPageNumber }) => {
  return (
    <div className="flex">
      <button className="uk-button" onClick={() => pageNumber > 0 ? setPageNumber(pageNumber - 1) : ''}>&lt;</button>
      {(new Array(totalPages)).fill(0).map((_, index) => (
        <button className={'uk-button ' + (pageNumber == index ? 'selected' : '')} onClick={() => setPageNumber(index)}>
          {index + 1}
        </button>
      ))}
      <button className="uk-button" onClick={() => pageNumber < totalPages - 1 ? setPageNumber(pageNumber + 1) : ''}>&gt;</button>
    </div>
  )
}

const SalonList = (
  // { list }
) => {
  const list = [1,2,3,4,5,6];
  const [pageNumber, setPageNumber] = useState(0);
  const entry_limit = 10;

  return (
    <div>
      <p className="uk-text-muted">
        Showing <span>{list.length}</span> Salons/Boutiques.
      </p>

      <div className="w-100 flex justify-center mb4">
        <Paginator totalPages={Math.ceil(list.length / entry_limit)} pageNumber={pageNumber} setPageNumber={num => setPageNumber(num)} />
      </div>

      {list.slice(pageNumber * entry_limit, (pageNumber + 1) * entry_limit).map(element => {
        return <SalonListElement key={element.id} salon={element} />
      })}

      <div className="w-100 flex justify-center mb4">
        <Paginator totalPages={Math.ceil(list.length / entry_limit)} pageNumber={pageNumber} setPageNumber={num => setPageNumber(num)} />
      </div>
    </div>
  )
}

export default SalonList
