import React, { useEffect, useState, useRef, useContext } from "react"

import "./search-bar.css"
import { Subject } from "rxjs"
import { SearchService } from "./search-service"
import SalonList from "../salon-list/salon-list"
import NearMeButton from "./NearMeButton/NearMeButton"
import SearchButton from "./SearchButton/SearchButton"
import SalonSearchList from "../SalonSearchList/SalonSearchList"
import { searchContext } from "../../context/searchContext/searchContext"
import axios from "axios"
import { base, URLS } from "../../config/urls"
import { filterContext } from "../../context/filterContext/filterContext"
import { filter_by } from "../../context/filterContext/filterConstants"
import { navigate } from "gatsby"
import { categoryContext } from "../../context/categoryContext/categoryContext"

const SearchBar = props => {
  const [searchResults, setSearchResults] = useState([])
  const searchTerm$ = new Subject()
  const searchService = new SearchService()
  const inputRef = useRef(null)
  const ctx = useContext(searchContext)
  const filterCtx = useContext(filterContext)
  const categoryCtx = useContext(categoryContext)

  useEffect(() => {
    searchService.search(searchTerm$).subscribe(results => {
      searchService.setSearchResults(results, searchResults => {
        // props.fetchTextBaseData(searchResults)
        setSearchResults(searchResults)
      })
    })
    searchTerm$.subscribe(value => {
      ctx.setLocation(null)
      ctx.setSearchString(value)
    })
  })

  useEffect(() => {
    if (!ctx.defaultSalonList.length) {
      ctx.getSalonsWithServices()
    }
  }, [])

  useEffect(() => {
    inputRef.current.setAttribute("value", ctx.searchString)
  }, [])
  let categoryList = categoryCtx.baseCategory.map(category => (
    <li className="uk-active">
      <a href="#">{category.name}</a>
      {category.subcategory.length > 0 && (
        <div className="uk-navbar-dropdown uk-navbar-dropdown-width-3">
          <div className="uk-navbar-dropdown-grid" data-uk-grid>
            <div className="uk-width-1-3@m">
              <ul className="uk-nav uk-navbar-dropdown-nav">
                <li
                  className="uk-nav-header"
                  style={{ fontWeight: "800 !important" }}
                >
                  {category.name}
                </li>
                {category.subcategory.map(subCat => (
                  <li className="uk-active">
                    <button
                      onClick={() => {
                        ctx.searchSubCategoryBaseSalon(subCat.id)
                        navigate("/listing")
                      }}
                    >
                      {subCat.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="uk-width-expand@m">
              <div
                className="w-100 h-100 uk-background-cover"
                data-src={category.pic}
                data-uk-img
              />
            </div>
          </div>
        </div>
      )}
    </li>
  ))

  return (
    <>
      <div
        className="uk-container uk-container-medium search-bar"
        style={{ padding: "0px 0px" }}
      >
        <div
          className="flex flex-column w-100 uk-box-shadow-small"
          style={{ background: "white" }}
        >
          <div
            className="search-bar-head flex justify-between flex-column flex-row-ns  ba b--black-05 pa2"
            style={{ background: "white" }}
          >
            <button
              href="#search-bar-body"
              className="uk-button uk-button-default uk-button-large w-100 w-20-ns uk-visible@s"
              type="button"
              data-uk-toggle="target: #search-bar-body; animation: uk-animation-fade"
              style={{ border: "none", backgroundColor: "transparent" }}
            >
              Customise <span uk-icon="icon: chevron-down"></span>
            </button>
            <div className="uk-inline uk-width-expand">
              <input
                ref={inputRef}
                className="uk-input uk-form-large uk-form-width-large uk-width-expand"
                type="text"
                onKeyUp={event => {
                  searchTerm$.next(event.target.value)
                }}
                placeholder="Search for treatments and saloons"
              />
              {(searchResults && searchResults.length) ? (
                <div
                  className="absolute w-100 uk-box-shadow-small search-result-box"
                  style={{ background: '#fff', zIndex: "20000" }}
                  // data-uk-dropdown="pos: bottom-center"
                >
                  <div>
                    <SalonSearchList list={searchResults}/>
                  </div>
                </div>
              ) : null}

            </div>
            <div className="flex">
              <NearMeButton {...props} />
              <button
                href="#search-bar-body"
                className="uk-button uk-button-default uk-button-large w-100 w-20-ns uk-hidden@s"
                type="button"
                data-uk-toggle="target: #search-bar-body; animation: uk-animation-fade"
                style={{ border: "none", backgroundColor: "transparent" }}
              >
                Customise <span uk-icon="icon: chevron-down"></span>
              </button>
            </div>

            <SearchButton
              {...props}
              text={inputRef.current ? inputRef.current.value : ""}
              searchResult={searchResults}
            />
          </div>
          <div
            id="search-bar-body"
            className=" pa2 pt3 pb0 flex justify-between flex-column flex-row-ns"
            hidden
          >
            <div className="w-100 w-60-ns pa4 pt2">
              <div className="uk-text-emphasis uk-text-bold mb2">
                Select Categories
              </div>
              <div className="uk-flex uk-flex-wrap uk-flex-between uk-child-width-auto">
                {categoryCtx.subCategory.slice(0, 6).map(val => {
                  return (
                    <a
                      className="pr2 pv2 uk-link-muted"
                      onClick={() => {
                        ctx.searchSubCategoryBaseSalon(val.id)
                        navigate("/listing")
                      }}
                    >
                      {val.name}
                    </a>
                  )
                })}
              </div>
            </div>
            {/* <div className="flex flex-column flex-row-ns w-100 w-60-ns justify-between">
              <div
                className="uk-margin flex flex-column"
                style={{ marginTop: "20px" }}
              >
                <div className="uk-margin">
                  <input
                    className="uk-input uk-button-default uk-form-medium uk-form-width-medium"
                    type="date"
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>
              <div className="uk-margin flex flex-column">
                <div className="uk-margin">
                  <input
                    className="uk-input uk-form-medium uk-form-width-medium"
                    type="time"
                    placeholder="hh/mm"
                  />
                </div>
              </div>
              <div className="uk-margin flex flex-column">
                <div className="uk-margin">
                  <input
                    className="uk-input uk-form-medium uk-form-width-medium"
                    type="number"
                    placeholder="110092"
                  />
                </div>
              </div>
            </div> */}
            <div className="w-100 w-40-ns pa4 pt2">
              <div className="uk-text-emphasis uk-text-bold mb3">
                Select Type
              </div>
              <div className="uk-grid uk-child-width-1-2" uk-grid>
                <div className="pb2">
                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="unisex"
                      checked={filterCtx.filterBy === filter_by.NONE}
                      onClick={() => filterCtx.setFilterBy(filter_by.NONE)}
                    />
                    <a className="ml2 pa2 uk-text-meta">ALL</a>
                  </label>
                </div>
                <div className="pb2">
                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="unisex"
                      checked={filterCtx.filterBy === filter_by.UNISEX}
                      onClick={() => filterCtx.setFilterBy(filter_by.UNISEX)}
                    />
                    <a className="ml2 pa2 uk-text-meta">Unisex</a>
                  </label>
                </div>
                <div className="pt1">
                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="male"
                      checked={filterCtx.filterBy === filter_by.MALE}
                      onClick={() => filterCtx.setFilterBy(filter_by.MALE)}
                    />
                    <a className="ml2 pa2 uk-text-meta">Only Male</a>
                  </label>
                </div>
                <div className="pt1">
                  <label>
                    <input
                      className="uk-radio"
                      type="radio"
                      name="female"
                      checked={filterCtx.filterBy === filter_by.FEMALE}
                      onClick={() => filterCtx.setFilterBy(filter_by.FEMALE)}
                    />
                    <a className="ml2 pa2 uk-text-meta">Only Female</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {searchResults && searchResults.length ? (
        <div>
          <SalonSearchList list={searchResults} />
        </div>
      ) : (
        <></>
      )} */}
    </>
  )
}

export default SearchBar
