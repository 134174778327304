import axios from "axios"
import { URLS } from "../config/urls";

export class SalonsService {

    constructor() { }
  
    getSalonDetails(id) {
      return axios.get(URLS.salonDetailsUrl(id));
    }
  
    getSalonServices(id) {
      return axios.get(URLS.salonServicesUrl(id));
    }
  
    getSalonMembers(id) {
      return axios.get(URLS.salonMembersUrl(id));
    }
  
    getSalonReviews(id) {
      return axios.get(URLS.salonReviewsUrl(id));
    }
  }